var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full md:w-max"},[_c('h3',{staticClass:"mb-5 text-lg font-semibold"},[_vm._v("Order History")]),(_vm.success)?_c('Alert',{class:{
      'opacity-100 transition-opacity duration-500': _vm.isVisible,
      'opacity-0 transition-opacity duration-500': !_vm.isVisible,
      'm-0 hidden': _vm.isHidden
    },attrs:{"variant":"green","icon":"check"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e(),(_vm.orders.length === 0 && !_vm.loading)?_c('p',{staticClass:"text-gray-500 text-sm"},[_vm._v("No orders created yet.")]):_vm._e(),(_vm.orders.length > 0)?_c('ol',{staticClass:"overflow-hidden border rounded-xl divide-y"},_vm._l((_vm.orders),function(order){return _c('li',{key:order.id,staticClass:"flex flex-row md:gap-x-32 items-center justify-between p-8 font-semibold",class:{
        'bg-green-50 transition-colors duration-500': order.isNew,
        'bg-transparent transition-colors duration-500': !order.isNew
      }},[_c('p',{staticClass:"min-w-max"},[_vm._v("Order #"+_vm._s(_vm.formatUuid(order.id)))]),_c('OrderStatusBadge',{attrs:{"status":order.status}})],1)}),0):_vm._e(),(_vm.loading)?_c('div',{staticClass:"flex justify-center mt-4"},[_c('LoadingSpinner')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }