<template>
  <div class="w-full md:w-max">
    <h3 class="mb-5 text-lg font-semibold">Order History</h3>

    <!-- Success Message -->
    <Alert
      v-if="success"
      :class="{
        'opacity-100 transition-opacity duration-500': isVisible,
        'opacity-0 transition-opacity duration-500': !isVisible,
        'm-0 hidden': isHidden
      }"
      variant="green"
      icon="check"
    >
      {{ successMessage }}
    </Alert>

    <!-- If no orders -->
    <p v-if="orders.length === 0 && !loading" class="text-gray-500 text-sm">No orders created yet.</p>

    <!-- Order List -->
    <ol v-if="orders.length > 0" class="overflow-hidden border rounded-xl divide-y">
      <li
        v-for="order in orders"
        :key="order.id"
        class="flex flex-row md:gap-x-32 items-center justify-between p-8 font-semibold"
        :class="{
          'bg-green-50 transition-colors duration-500': order.isNew,
          'bg-transparent transition-colors duration-500': !order.isNew
        }"
      >
        <p class="min-w-max">Order #{{ formatUuid(order.id) }}</p>
        <OrderStatusBadge :status="order.status" />
      </li>
    </ol>

    <div v-if="loading" class="flex justify-center mt-4">
      <LoadingSpinner />
    </div>
  </div>
</template>

<script>
import OrderServiceV2 from '@/lib/order-service-v2';
import Alert from '@/components/ui/Alert';
import OrderStatusBadge from '@/components/OrderStatusBadge.vue';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import { mapState } from 'vuex';

export default {
  props: {
    successMessage: {
      type: String,
      default: 'Order successfully created!'
    },
    success: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Alert,
    OrderStatusBadge,
    LoadingSpinner
  },
  data() {
    return {
      orders: [],
      loading: true,
      isVisible: false,
      isHidden: true,
      timeout: null
    };
  },
  computed: {
    ...mapState({
      sessionUser: (state) => state.user
    })
  },
  async mounted() {
    if (this.sessionUser) {
      await this.fetchOrders();
    }
  },
  methods: {
    async fetchOrders() {
      if (!this.sessionUser) return;
      this.loading = true;

      try {
        const organizationUuid = this.sessionUser.organizationUuid;
        if (!organizationUuid) {
          console.warn('No organization UUID found');
          this.loading = false;
          return;
        }

        // Get last 10 orders
        const response = await OrderServiceV2.listOrders({
          organizationId: organizationUuid,
          createdBy: this.sessionUser.id,
          pageSize: 10,
          sortBy: 'createdAt',
          sortDir: 'desc'
        });

        // Store orders
        this.orders = response.data.map((order) => ({
          id: order.id,
          status: order.status,
          isNew: false
        }));
      } catch (error) {
        console.error('Error fetching orders:', error.response ? error.response.data : error);
      } finally {
        this.loading = false;
      }
    },
    async refreshOrders(newOrderId, newOrderStatus) {
      // Add new order to top of the list
      this.orders.unshift({
        id: newOrderId,
        status: newOrderStatus,
        isNew: true
      });

      // Keep only the last 10 orders
      if (this.orders.length > 10) {
        this.orders.pop();
      }

      // Show success message of the order created and then fade out after 5s
      this.isHidden = false;
      this.isVisible = true;

      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.orders = this.orders.map((order) => ({ ...order, isNew: false }));
        this.isVisible = false;

        setTimeout(() => {
          this.isHidden = true;
        }, 500);
      }, 5000);
    }
  }
};
</script>
