<template>
  <div class="mx-3 md:mx-24 mt-20 mb-40">
    <h1>Create Order</h1>

    <div v-if="loading">
      <LoadingSpinner class="m-auto" />
    </div>
    <div class="flex flex-col md:flex-row gap-4 md:gap-20" v-else>
      <div class="max-w-7xl">
        <Alert class="mb-6" variant="red" icon="exclamation" v-if="errorMessage">{{ errorMessage }}</Alert>
        <div>
          <OrderDetailsForm
            @updateOrderDetails="updateOrderDetails"
            :editOrderDetails="orderDetails"
            action="create"
            ref="orderDetails"
            class="mb-5"
          />
          <AddOrderItemForm @addToCart="addToCart" :options="eventOptions" />
          <CreateOrderCart
            :cartItems="cartItems"
            :donationAmount="donationAmount"
            :memberOptIn="memberOptIn"
            :isHomeLottery="isHomeLottery"
            @updateDonation="updateDonation"
            @updateMemberOptIn="updateMemberOptIn"
            @deleteItem="removeFromCart"
            class="mb-5"
          />
          <CustomerInformationForm
            @updateCustomerInformation="updateCustomerInformation"
            :editCustomerInformation="customerInformation"
            :required="true"
            ref="customerInformation"
          />
        </div>
      </div>
      <OrderHistory ref="orderHistory" :success="success" :successMessage="successMessage" />
    </div>
    <div v-if="!loading" class="bg-white z-10 px-20 py-8 fixed bottom-0 left-0 w-100 flex justify-end custom-shadow">
      <div class="flex flex-wrap gap-2 justify-center">
        <BaseButton variant="secondary" @click.native="resetForm"> Reset </BaseButton>
        <GenericModal ref="captureFailedModal" :error-message="modalErrorMessage">
          <template #openButton="{ openModal }">
            <div>
              <BaseButton variant="danger-outline" @click.native="openModal"> Capture Failed </BaseButton>
            </div>
          </template>
          <template #header>
            <div class="text-lg rb-modal__title">Capture Failed</div>
          </template>
          <template>
            <div class="p-4">
              <h6 class="font-semibold mb-2">
                Reason for failure
                <span class="text-danger">*</span>
              </h6>
              <b-form-group
                label-for="input-failure-reason"
                :invalid-feedback="veeErrors.first('input-failure-reason')"
              >
                <b-form-textarea
                  name="input-failure-reason"
                  id="textarea"
                  v-model="orderDetails.reason"
                  placeholder="Enter reason..."
                  :state="validateState('input-failure-reason')"
                  :required="true"
                  rows="6"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </template>
          <template #footer="{ closeModal }">
            <div class="flex gap-2 py-2">
              <BaseButton @click.native="closeModal" class="mr-2" aria-label="Close modal"> Close </BaseButton>
              <BaseButton @click.native="submit('capture-failed')" :loading="submitting" variant="danger-outline">
                Capture Failed
              </BaseButton>
            </div>
          </template>
        </GenericModal>
        <BaseButton variant="success" :disabled="loading" :loading="submitting" @click.native="submit()">
          Create
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import OrderDetailsForm from '@/components/forms/OrderDetailsForm';
import CustomerInformationForm from '@/components/forms/CustomerInformationForm';
import AddOrderItemForm from '@/components/forms/AddOrderItemForm';
import CreateOrderCart from '@/components/CreateOrderCart.vue';
import { parseISO, set } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { v4 as uuidv4 } from 'uuid';

import EventServiceV2 from '@/lib/event-service-v2';
import PaymentService from '@/lib/payment-service';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import GenericModal from '@/components/modals/GenericModal.vue';
import OrderHistory from '@/components/OrderHistory.vue';
import getCardType from '@/lib/card-type';

export default {
  components: {
    Alert,
    OrderDetailsForm,
    CustomerInformationForm,
    AddOrderItemForm,
    CreateOrderCart,
    BaseButton,
    LoadingSpinner,
    GenericModal,
    OrderHistory
  },
  data() {
    return {
      modalShow: false,
      disabled: false,
      orderDetails: {
        orderType: null,
        orderStatus: null,
        orderSource: null,
        orderReceivedDate: null,
        referenceId: null,
        batchId: null,
        campaignId: null,
        reason: null
      },
      customerInformation: {
        firstName: null,
        lastName: null,
        title: null,
        age: null,
        address: null,
        city: null,
        province: null,
        postalCode: null,
        email: null,
        phone: null,
        additionalNames: null,
        comment: null,
        shippingAddressLine1: null,
        shippingCity: null,
        shippingState: null,
        shippingPostal: null
      },
      cartItems: [],
      purchasedEvents: [],
      eventOptions: [],
      donationAmount: 0,
      memberOptIn: false,
      errorMessage: null,
      modalErrorMessage: null,
      submitting: false,
      loading: false,
      success: false,
      successMessage: 'Order successfully created!',
      recentOrders: [],
      isHomeLottery: false
    };
  },
  async mounted() {
    this.loading = true;
    const params = {
      status: 'not_ended_and_active',
      childEvents: true,
      pageSize: 50
    };

    this.setFormDefaults();

    try {
      const optionsEvents = [];

      optionsEvents.push({ value: null, text: '--- Select a Raffle ---' });

      const response = await EventServiceV2.listEvents(params);

      for (const event of response.data) {
        optionsEvents.push({
          value: event.id,
          text: event.name,
          ticketPackages: event.ticketPackages,
          event: event,
          disabled: false
        });
      }

      this.isHomeLottery = optionsEvents.filter((option) => option?.event?.isHomeLottery).length > 0 ? true : false;

      this.eventOptions = optionsEvents;
      this.loading = false;
    } catch (error) {
      this.errorMessage = this.parseError(error).message;
      this.loading = false;
    }
  },
  methods: {
    showAlert() {
      setTimeout(() => {
        this.success = false;
      }, 5000);
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },
    updateOrderDetails(orderDetails) {
      this.orderDetails = orderDetails;
    },
    updateCustomerInformation(customerInformation) {
      this.customerInformation = customerInformation;
    },
    updateDonation(donation) {
      this.donationAmount = donation;
    },
    updateMemberOptIn(value) {
      this.memberOptIn = value;
    },
    setFormDefaults() {
      const isAgent = this.$store.state.user.roles.includes('Contact Center Agent');

      // Set defaults for these fields if the user is an Agent
      if (isAgent) {
        this.orderDetails.orderStatus = 'active';
        this.orderDetails.orderType = 'credit';
        this.orderDetails.orderSource = 'phone';
      }
    },
    addToCart(event, ticketPackages, quantities) {
      const selectedPackages = [];

      for (const selection in quantities) {
        const tp = ticketPackages.find((pkg) => pkg.id === selection);
        selectedPackages.push({
          ...tp,
          quantity: Number(quantities[tp.id])
        });
      }

      this.cartItems.push({
        event,
        ticketPackages: selectedPackages
      });
      this.purchasedEvents.push(event.id);

      // Set donationEnabled prop on cartItems to determine if donations are enabled for the order group
      this.cartItems.donationEnabled = this.cartItems.some((item) => item.event.settings.donationEnabled);

      const { id, parentEventId } = event;

      let disabledOptions = [];

      // Look for other child events
      if (parentEventId) {
        disabledOptions = this.eventOptions.map((event) => {
          // Account for no option
          if (event.value == null) {
            return event;
          }

          // Account for already purchased events
          if (this.purchasedEvents.includes(event.event.id)) {
            return { ...event, disabled: true };
          }

          if (event.event.id === parentEventId) {
            return event;
          }

          if (event.event.parentEventId !== parentEventId) {
            return { ...event, disabled: true };
          } else {
            return event;
          }
        });
      } else {
        // Look for children of the paernt
        disabledOptions = this.eventOptions.map((event) => {
          // Account for no option
          if (event.value == null) {
            return event;
          }

          // Account for already purchased events
          if (this.purchasedEvents.includes(event.event.id)) {
            return { ...event, disabled: true };
          }

          if (event.event.parentEventId !== id) {
            return { ...event, disabled: true };
          } else {
            return event;
          }
        });
      }

      this.eventOptions = disabledOptions;
    },
    removeFromCart(row) {
      this.cartItems = this.cartItems.filter((item) => item.event.id !== row.event.id);
      const filtered = this.purchasedEvents.filter((id) => id !== row.event.id);
      this.purchasedEvents = filtered;

      // Update donationEnabled prop on cartItems in case event with donation enabled was removed
      this.cartItems.donationEnabled = this.cartItems.some((item) => item.event.settings.donationEnabled);

      if (this.purchasedEvents.length == 0) {
        // Enable all options if cart is cleared
        this.eventOptions = this.eventOptions.map((event) => {
          return { ...event, disabled: false };
        });
      } else {
        // Enable the option that was removed from the cart
        this.eventOptions = this.eventOptions.map((event) => {
          if (event.value === row.event.id) {
            return { ...event, disabled: false };
          }
          return event;
        });
      }
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    async validateAll() {
      const validOrderDetails = await this.$refs.orderDetails.$validator.validateAll();
      const validCustomerInformation =
        (await this.$refs.customerInformation.customValidation()) &&
        (await this.$refs.customerInformation.$validator.validateAll());

      return validOrderDetails && validCustomerInformation;
    },
    resetValidation() {
      this.$refs.orderDetails.$validator.reset();
      this.$refs.customerInformation.resetValidation();
    },
    async submit(captureFailedSubmit) {
      try {
        this.errorMessage = null;
        this.modalErrorMessage = null;

        if (captureFailedSubmit) {
          if (this.orderDetails.reason === null) {
            this.modalErrorMessage = 'Please enter reason for failure';
            return;
          }
        }

        const validationSuccess = await this.validateAll();

        if (!validationSuccess) {
          this.$refs.captureFailedModal.closeModal();
          this.errorMessage = 'Please fill out all required fields.';
          this.scrollToTop();
          return;
        }

        this.submitting = true;

        // Convert the date to UTC
        let dateTime;

        if (this.orderDetails.orderReceivedDate) {
          if (this.orderDetails.orderReceivedDate.includes('T')) {
            dateTime = this.orderDetails.orderReceivedDate;
          } else {
            const date = set(parseISO(this.orderDetails.orderReceivedDate), {
              hours: 0,
              minutes: 0,
              seconds: 0,
              milliseconds: 0
            });

            const utcToZonedTime = zonedTimeToUtc(date, this.$store.getters.getOrganization.timeZone).toISOString();

            dateTime = utcToZonedTime;
          }
        }

        // Format cart items
        const cartItems = [];

        for (const item of this.cartItems) {
          const packages = item.ticketPackages.map((pkg) => {
            return {
              ticketPackageId: pkg.id,
              numPackages: pkg.quantity
            };
          });

          for (const pkg of packages) {
            if (pkg.numPackages > 0) {
              cartItems.push({
                ticketPackageId: pkg.ticketPackageId,
                numPackages: pkg.numPackages
              });
            }
          }
        }

        // Get child raffle with parent event ID
        const childRaffle = this.cartItems.find((item) => {
          return item.event.parentEventId !== null;
        });

        // Get parent raffle to use for the eventId if this is the only raffle ordered from
        const parentRaffle = this.cartItems.find((item) => {
          return item.event.childEvents.length !== 0;
        });

        // Handle the case where there there is no parent/child relationship on the raffle in the cart
        const eventId =
          !childRaffle && !parentRaffle
            ? this.cartItems[0].event.id
            : childRaffle?.event.parentEventId || parentRaffle?.event.id;

        // Create order object
        const order = {
          eventId,
          purchaser: {
            firstName: this.customerInformation.firstName,
            lastName: this.customerInformation.lastName,
            secondaryName: this.isStringEmpty(this.customerInformation.additionalNames),
            email: this.customerInformation.email || undefined,
            phone: this.customerInformation.phone || undefined,
            address: this.customerInformation.address || undefined,
            city: this.customerInformation.city || undefined,
            province: this.customerInformation.province || undefined,
            postal: this.customerInformation.postalCode?.replace(/\s/g, '') || undefined,
            age: this.isStringEmpty(this.customerInformation.age),
            title: this.isStringEmpty(this.customerInformation.title),
            shippingEnabled: this.customerInformation.shippingEnabled
          },
          source: this.orderDetails.orderSource || undefined,
          paymentType: this.orderDetails.orderType,
          cardBrand: getCardType(this.orderDetails.cardType) || undefined,
          comment: this.customerInformation.comment || undefined,
          cartItems: cartItems,
          pending: this.orderDetails.orderStatus === 'pending',
          donationAmountCents: this.donationAmount ? this.donationAmount * 100 : 0,
          batchId: this.orderDetails.batchId || undefined,
          referenceId: this.isStringEmpty(this.orderDetails.referenceId),
          campaignId: this.isStringEmpty(this.orderDetails.campaignId),
          receivedDate: dateTime ?? undefined,
          clientTxId: uuidv4(),
          referral: this.orderDetails.referral || undefined,
          isMember: this.memberOptIn
        };

        if (this.orderDetails.reason) {
          // Hard-coded errorCode of AFO for Agent Failed Order
          order.errorCode = 'AFO';
          order.errorMessage = this.orderDetails.reason || undefined;
        }

        // If shipping address is filled out use it, otherwise use billing addresss
        if (!this.customerInformation.shippingEnabled) {
          order.purchaser.shippingAddressLine1 = this.customerInformation.shippingAddressLine1 || undefined;
          order.purchaser.shippingCity = this.customerInformation.shippingCity || undefined;
          order.purchaser.shippingState = this.customerInformation.shippingState || undefined;
          order.purchaser.shippingPostal = this.customerInformation.shippingPostal?.replace(/\s/g, '') || undefined;
        } else {
          order.purchaser.shippingAddressLine1 = this.customerInformation.address || undefined;
          order.purchaser.shippingCity = this.customerInformation.city || undefined;
          order.purchaser.shippingState = this.customerInformation.province || undefined;
          order.purchaser.shippingPostal = this.customerInformation.postalCode?.replace(/\s/g, '') || undefined;
        }

        // Hold these options if the user wants to create another order
        const savedForAnother = {
          orderType: this.orderDetails.orderType,
          orderStatus: this.orderDetails.orderStatus,
          orderSource: this.orderDetails.orderSource
        };

        // Call the API to create the order
        const response = await PaymentService.createOrder(order);

        // Handle post-submit actions
        this.submitting = false;
        if (captureFailedSubmit) {
          this.$refs.captureFailedModal.closeModal();
        }
        this.scrollToTop();
        this.resetForm();

        // Reset fields with defaults
        this.orderDetails.orderType = savedForAnother.orderType;
        this.orderDetails.orderStatus = savedForAnother.orderStatus;
        this.orderDetails.orderSource = savedForAnother.orderSource;

        // Refresh Order History with new order
        this.$refs.orderHistory.refreshOrders(this.formatUuid(response.order.id), response.order.status);

        // Success message
        this.success = true;
        this.successMessage = `Order #${this.formatUuid(response.order.id)} successfully created!`;
      } catch (error) {
        this.submitting = false;
        this.errorMessage = this.parseError(error).message;
      }
    },
    resetForm() {
      this.orderDetails = {
        orderType: null,
        orderStatus: null,
        orderSource: null,
        orderReceivedDate: null,
        referenceId: null,
        batchId: null,
        campaignId: null,
        reason: null
      };
      this.customerInformation = {
        firstName: null,
        lastName: null,
        title: null,
        age: null,
        address: null,
        city: null,
        province: null,
        postalCode: null,
        email: null,
        phone: null,
        additionalNames: null,
        comment: null,
        shippingAddressLine1: null,
        shippingCity: null,
        shippingState: null,
        shippingPostal: null,
        shippingEnabled: false
      };
      this.cartItems = [];
      this.donationAmount = 0;
      this.eventOptions = this.eventOptions.map((event) => {
        return { ...event, disabled: false };
      });
      this.purchasedEvents = [];
      this.errorMessage = null;
      this.modalErrorMessage = null;
      this.resetValidation();
      this.memberOptIn = false;
    }
  }
};
</script>

<style scoped>
.custom-shadow {
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.06);
}
</style>
